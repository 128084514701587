import React from "react";
import {
  Grid,
  Image,
  Space,
  Text,
  Title,
  Group,
  Rating,
  Button,
  Avatar,
  Card,
} from "@mantine/core";
import "@mantine/carousel/styles.css";
import classes from "./Carousel.module.css";
import { Link } from "react-router-dom";
import { Carousel } from "@mantine/carousel";

const cardDetails = {
  0: {
    imgUrl: "/assets/images/part1.svg",
    title: "Client 1",
  },
  1: {
    imgUrl: "/assets/images/part2.png",
    title: "Client 2",
  },
  2: {
    imgUrl: "/assets/images/part3.svg",
    title: "Client 3",
  },
  3: {
    imgUrl: "/assets/images/part4.png",
    title: "Client 3",
  },
  4: {
    imgUrl: "/assets/images/part5.png",
    title: "Client 3",
  },
  5: {
    imgUrl: "/assets/images/part6.svg",
    title: "Client 3",
  },
  6: {
    imgUrl: "/assets/images/part7.png",
    title: "Client 3",
  },
};

export default function About() {
  function CarouselItem({ imgUrl, imgTitle }) {
    return (
      <div className="carousel-card">
        <img src={imgUrl} alt={imgTitle} style={{ maxWidth: "unset" }}></img>
      </div>
    );
  }

  return (
    <>
      <div className="tw-relative lg:tw-h-[580px] tw-h-[525px] tw-border-b-[2px] tw-border-black lg:tw-pt-20">
        <div>
          <Title
            visibleFrom="md"
            mt={30}
            style={{ textAlign: "center" }}
            fz={{ lg: 64, base: 32 }}
          >
            Empowering Businesses,
          </Title>
          <Title
            visibleFrom="md"
            style={{ textAlign: "center" }}
            fz={{ lg: 64, base: 32 }}
          >
            Setting New Standards
          </Title>
        </div>
        <div className="tw-p-2">
          <Title
            hiddenFrom="md"
            mt={30}
            style={{ textAlign: "center" }}
            fz={{ lg: 64, base: 32 }}
          >
            Empowering
          </Title>
          <Title
            hiddenFrom="md"
            style={{ textAlign: "center" }}
            fz={{ lg: 64, base: 32 }}
          >
            Businesses, Setting
          </Title>
          <Title
            hiddenFrom="md"
            style={{ textAlign: "center" }}
            fz={{ lg: 64, base: 32 }}
          >
            {" "}
            New Standards
          </Title>
        </div>
        <Text
          mt={20}
          style={{
            textAlign: "center",
            width: "75%",
            marginLeft: "auto",
            marginRight: "auto",
            lineHeight: "30px",
          }}
          fz={18}
        >
          We empower businesses and set new standards for cleanliness and
          hygiene. With a relentless dedication to excellence, we strive to
          transform spaces into inviting and pristine environments where
          businesses thrive and homes flourish. Discover how OZLINK is
          revolutionizing the cleaning industry and elevating standards across
          Australia.
        </Text>
        <img
          src="/assets/images/melbourne_line.jpeg"
          alt="Melbourne Line"
          className="tw-absolute tw-bottom-[0px] tw-opacity-[20%]"
        />
      </div>
      <Space h={60} />
      <div className="tw-container tw-mx-auto tw-w-[85%]">
        <Title fw={600} fz={{ lg: 36, base: 24 }}>
          Our Story
        </Title>
        <Grid mt={24}>
          <Grid.Col span={{ lg: 6, base: 12 }} order={{ lg: 1, base: 2 }}>
            <Text mt={4} fz={16} style={{ letterSpacing: "-2%" }}>
              OZLINK is a Queensland-based company specializing in Industrial &
              commercial cleaning and maintenance services. With over a decade
              of expertise in the cleaning industry, OZLINK brings exceptional
              and distinguished services tailored to satisfy the unique
              requirements of our customers.
            </Text>
            <Text mt={4} fz={16} style={{ letterSpacing: "-2%" }}>
              Head office based in Brisbane, Queensland, along with expansions
              in VIC, NSW, TAS, ACT and NT, we cater to a diverse range of
              clientele including education, healthcare, retails, commercial and
              industrial sectors.
            </Text>
            <Text mt={4} fz={16} style={{ letterSpacing: "-2%" }}>
              With over 200 skilled and highly trained professionals, OZLINK
              offers a comprehensive range of services specializing in soft and
              hard floor care, building washing, Carpark pressure washing,
              Internal & External window cleaning, Garden care and much more.
            </Text>
            <Text mt={4} fz={16} style={{ letterSpacing: "-2%" }}>
              By utilizing environmentally safe cleaning products and efficient
              cleaning techniques, OZLINK is committed to minimizing
              environmental impact while ensuring Unparalleled results. Customer
              satisfaction is at the core of OZLINK's mission, we strive to go
              above and beyond client expectations through our unwavering
              commitment to reliable service, keen attention to detail, and
              exceptional results.
            </Text>
          </Grid.Col>
          <Grid.Col span={{ lg: 6, base: 12 }} order={{ lg: 2, base: 1 }}>
            <Image src="/assets/images/working.jpg" />
          </Grid.Col>
        </Grid>
      </div>

      <div className="lg:tw-mt-24 tw-mt-16 tw-mx-auto tw-w-[80%]">
        <Title
          fw={400}
          fz={{ base: 20, lg: 22 }}
          style={{ textAlign: "center" }}
        >
          Elevate your commercial space with our tailored cleaning solutions,
          designed for excellence and peace of mind. Experience the difference
          in commercial cleaning- contact Ozlink today.
        </Title>
        {/* <Title fw={600} fz={{ base: 14, lg: 22 }} hiddenFrom='lg' style={{ textAlign: "center" }}>Elevate your commercial space with our tailored cleaning solutions, designed for excellence and peace of mind. Experience the difference in commercial cleaning- contact Ozlink today.</Title> */}
        <div className="tw-flex tw-justify-center lg:tw-mt-16 tw-mt-8">
          <div className="carousel-container tw-flex tw-justify-center">
            <Link to={"/contact-us"}>
              <Button
                size="xl"
                px={30}
                style={{
                  border: "4px solid #434343",
                }}
                visibleFrom="lg"
                radius={30}
                c={"#434343"}
                variant="outline"
              >
                Contact us
              </Button>
            </Link>
            <Link to={"/contact-us"}>
              <Button
                size="md"
                px={20}
                style={{
                  border: "2px solid #434343",
                }}
                mt={8}
                fz={16}
                hiddenFrom="lg"
                radius={30}
                c={"#434343"}
                variant="outline"
              >
                Contact us
              </Button>
            </Link>
          </div>
        </div>
      </div>

      <div className="lg:tw-mt-24 tw-mt-16 tw-mx-auto tw-w-[85%]">
        <Title
          fw={600}
          fz={{ base: 24, lg: 32 }}
          visibleFrom="lg"
          style={{ textAlign: "center" }}
        >
          Some of Our Commercial Clients
        </Title>
        <Title
          fw={600}
          fz={{ base: 24, lg: 32 }}
          hiddenFrom="lg"
          style={{ textAlign: "center" }}
        >
          Some of Our <br /> Commercial Clients
        </Title>
        <div className="tw-flex tw-justify-center lg:tw-mt-16 tw-mt-8">
          <div className="carousel-container">
            <div className="carousel-track">
              {Object.keys(cardDetails).map((detailKey) => {
                return (
                  <CarouselItem
                    imgUrl={cardDetails[detailKey].imgUrl}
                    imgTitle={cardDetails[detailKey].title}
                  ></CarouselItem>
                );
              })}
              {Object.keys(cardDetails).map((detailKey) => {
                return (
                  <CarouselItem
                    imgUrl={cardDetails[detailKey].imgUrl}
                    imgTitle={cardDetails[detailKey].title}
                  ></CarouselItem>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="tw-bg-[#142049] tw-mt-16">
        <div className="tw-mx-auto tw-w-[85%] tw-py-12 tw-text-white">
          <div>
            <h1 className="tw-text-center lg:tw-text-left tw-text-[24px] tw-font-[600] tw-pb-6">
              Leadership
            </h1>
            {/* <p className="tw-text-[16px] tw-font-[400] tw-my-10 tw-text-left">
              Our team is trained both internationally and locally, so they
              understand your needs and the environment in which you do
              business. 
            </p> */}
          </div>
          <Grid>
            <Grid.Col span={{ lg: 5, base: 12 }}>
              <div className="tw-flex xl:tw-justify-start tw-justify-center">
                <Image
                  h={{ md: 420, sm: 380, xs: 360, base: "auto" }}
                  src="/assets/images/lead.jpg"
                  alt="Lead"
                  radius={20}
                />
              </div>
            </Grid.Col>
            <Grid.Col span={{ lg: 7, base: 12 }}>
              <div className="lg:tw-pl-5">
                <Title
                  c="#86FF68"
                  fw={500}
                  my={{ base: 16, lg: 0 }}
                  fz={{ base: 18, md: 24 }}
                >
                  Chamin Herath
                </Title>
                <Text my={16} fw={600}>
                  Founder/ CEO
                </Text>
                <Text className=" tw-text-justify" lh={"24px"} fz={16}>
                  Chamin Herath, the founder and director of OZLINK, has been
                  leading our mission to provide high-quality, customized
                  commercial cleaning services to businesses across Brisbane and
                  Queensland for over 11 years. With a commitment to excellence
                  and safe practices, Chamin has overseen the expansion of our
                  services into other states such as VIC, TAS, and NT.
                </Text>
                <Text className=" tw-text-justify" mt={16} fz={16}>
                  Under Chamin's leadership, our highly skilled and dedicated
                  teams have thrived, delivering exceptional service under a
                  business model that prioritizes quality and safety. With
                  extensive experience in the industry, Chamin ensures that
                  OZLINK maintains excellent standards while serving a diverse
                  range of industries, including industrial, retail, office,
                  health, exercise, education, and medical centers. Our cleaning
                  teams are meticulously trained and inducted to serve these
                  locations, acquiring transferrable skills and experience that
                  enhance our ability to deliver outstanding results
                  on every contract.
                </Text>
              </div>
            </Grid.Col>
          </Grid>
        </div>
      </div>
      <div className="tw-mx-auto tw-w-[85%]" id="testimonials">
        <div>
          <Title
            mt={42}
            fw={600}
            mb={24}
            style={{
              textAlign: "center",
            }}
          >
            Customer Testimonials
          </Title>
          <div>
            <Grid>
              <Grid.Col
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                span={{
                  base: 12,
                  md: 5,
                  lg: 6,
                  xl: 6,
                }}
              >
                <Carousel
                  classNames={classes}
                  withIndicators
                  withControls={false}
                >
                  <Carousel.Slide>
                    <Card radius={25} withBorder p={24}>
                      <Group justify="space-between">
                        <Group justify="flex-start">
                          <Avatar src="/assets/images/sarah.jpg" />
                          <div>
                            <Title fz={18}>Aaron McCabe</Title>
                            <Text c="#747474" fz="xs" fw={600}>
                              Operations Manager @ SKG services
                            </Text>
                          </div>
                        </Group>
                        <Rating defaultValue={5} color="#89EB0D" />
                      </Group>
                      <div className="tw-mt-6">
                        <Text fz={12} c="#747474">
                          OZLINK has been our go-to cleaning service provider
                          for years now, and we couldn't be happier with their
                          professionalism and attention to detail. Their team
                          always goes above and beyond to ensure our retail
                          spaces are spotless and welcoming for our customers.
                          Highly recommend their services.
                        </Text>
                      </div>
                    </Card>
                  </Carousel.Slide>
                  <Carousel.Slide>
                    <Card radius={25} withBorder p={24}>
                      <Group justify="space-between">
                        <Group justify="flex-start">
                          <Avatar src="/assets/images/john.jpg" />
                          <div>
                            <Title fz={18}>Hash</Title>
                            <Text c="#747474" fz="xs" fw={600}>
                              {" "}
                              
                            </Text>
                          </div>
                        </Group>
                        <Rating defaultValue={5} color="#89EB0D" />
                      </Group>
                      <div className="tw-mt-6">
                        <Text fz={12} c="#747474">
                          Highly recommend uptrend commercial Cleaning Service. Very friendly, efficient, thorough, professional, Punctual and reliable cleaning teams.
                          Ozlink cleaning service is the best I have come across. Excellent Communication  with updating and confirming about arrival and completion of the job. Five Star Service. Value for money.
                        </Text>
                      </div>
                    </Card>
                  </Carousel.Slide>
                  <Carousel.Slide>
                    <Card radius={25} withBorder p={24}>
                      <Group justify="space-between">
                        <Group justify="flex-start">
                          {/* <Avatar src="/assets/images/emily.jpg" /> */}
                          <div>
                            <Title fz={18}>Matt</Title>
                            <Text c="#747474" fz="xs" fw={600}>
                              {/* Director of Operations @ Health Solutions{" "} */}
                            </Text>
                          </div>
                        </Group>
                        <Rating defaultValue={4} color="#89EB0D" />
                      </Group>
                      <div className="tw-mt-6">
                        <Text over fz={12} c="#747474">
                        Very punctual , Well priced and provided an excellent service. We had a full office block with kitchens, common rooms ect for an end of lease clean and simply cannot fault the job that was done! Highly recommended.
                        </Text>
                      </div>
                    </Card>
                  </Carousel.Slide>
                </Carousel>
              </Grid.Col>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}
