import React, { useState } from 'react';
import {
    Button,
    Divider,
    Space,
    Text,
    Image,
    Input,
    Stack,
    Select,
    Textarea,
    Card,
    Group,
    Anchor,
    Title,
    Accordion,
    Rating,
    Grid
} from '@mantine/core';
import { Link } from 'react-router-dom';

import { questions, latestNews } from '../dummy';

export default function Home() {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        telephone: '',
        serviceType: '',
        msg: ''
    });

    const newsCards = latestNews.slice(0, 3);

    async function handleFormSubmit() {
        // Submit form
    }

    const freQuestions = questions.map((item) => (
        <Accordion.Item
            p={8}
            bg={"#fff"}
            mb={18}
            className='tw-rounded-lg'
            key={item.id}
            value={item.value}>
            <Accordion.Control><Text fw={500}>{item.title}</Text></Accordion.Control>
            <Accordion.Panel>
                <Divider size="sm" color='black' />
                <Text mt={12} className='tw-font-[400] tw-leading-[24px]' fz={16} c={"#686868"}>{item.answer}</Text>
            </Accordion.Panel>
        </Accordion.Item>
    ));

    return (
        <div>
            <div className='tw-relative tw-pt-5'>

                <div className='tw-grid tw-grid-cols-1 '>
                    <div className='lg:tw-order-1 tw-order-1'>
                        <div className="tw-mx-auto tw-w-[85%] lg:tw-mt-16 tw-mt-12 tw-relative ">
                            <div className='tw-absolute  tw-hidden tw-z-20 md:tw-flex tw-justify-center tw-items-center tw-w-full tw-h-full tw-bg-[#00000057] tw-rounded-[35px]'>

                            </div>
                            <div className='tw-absolute  md:tw-flex tw-hidden  tw-justify-around tw-items-center tw-w-full tw-mt-8'>
                                <button className='tw-px-[14px] tw-w-[220px] animate__animated animate__fadeInUp animate_delay-1s tw-z-20 tw-py-[8px]  tw-border-4 tw-border-[#86FF68] tw-bg-[#00000065] tw-font-bold tw-text-[16px] tw-text-[#ffffff] tw-rounded-[10px]'>Commercial Cleaning</button>
                                <button className='tw-px-[14px] tw-w-[220px]  animate__animated animate__fadeInUp animate_delay-1s tw-z-20 tw-py-[8px]  tw-border-4 tw-border-[#86FF68] tw-bg-[#00000065] tw-font-bold tw-text-[16px] tw-text-[#ffffff] tw-rounded-[10px]'>Security Services</button>
                                <button className='tw-px-[14px] tw-w-[220px]  animate__animated animate__fadeInUp animate_delay-1s tw-z-20 tw-py-[8px]  tw-border-4 tw-border-[#86FF68] tw-bg-[#00000065] tw-font-bold tw-text-[16px] tw-text-[#ffffff] tw-rounded-[10px]'>Garden Care</button>
                                <button className='tw-px-[14px] tw-w-[220px]  animate__animated animate__fadeInUp animate_delay-1s tw-z-20 tw-py-[8px]  tw-border-4 tw-border-[#86FF68] tw-bg-[#00000065] tw-font-bold tw-text-[16px] tw-text-[#ffffff] tw-rounded-[10px]'>Maintenance</button>
                            </div>

                            <div className=' tw-absolute tw-z-50 tw-hidden md:tw-flex tw-flex-col tw-w-full tw-h-full tw-justify-center tw-items-center'>

                                <h5 className='animate__animated animate__fadeInUp animate__delay-1s tw-text-center tw-text-[20px] tw-font-[500] tw-hidden md:tw-block tw-leading-[28px] tw-mt-5 tw-text-white'>
                                    Transform your space with our expert touch. <br />
                                    Ready to shine? Let's begin.
                                </h5>

                                <div className='tw-flex tw-justify-center tw-mt-10'>
                                    <Link to="/contact-us" className='tw-px-[19px] animate__animated animate__fadeInUp animate_delay-1s tw-z-20 tw-py-[17px] tw-bg-[#86FF68] tw-font-bold tw-text-[16px] tw-rounded-[10px]'>Get a Free Quote</Link>
                                </div>
                            </div>

                            <div className='tw-flex tw-justify-center'>
                                <img
                                    src="assets/images/main-banner.jpg"
                                    alt="Cleaning Lady"
                                    className='lg:tw-h-full' />
                            </div>

                        </div>
                        <div className='tw-order-2 lg:tw-order-2 tw-w-[85%] tw-mx-auto md:tw-hidden'>
                            <div className=' tw-flex md:tw-hidden tw-w-full tw-justify-bettween tw-items-center tw-flex-wrap  tw-mt-8'>
                                <button className='tw-px-[14px] tw-w-[48%] tw-mb-3 animate__animated animate__fadeInUp animate_delay-1s tw-z-20 tw-py-[8px]  tw-border-2 tw-border-[#86FF68] tw-bg-[#00000065] tw-font-bold tw-text-[12px] tw-text-[#ffffff] tw-rounded-[10px]'>Commercial Cleaning</button>
                                <button className='tw-px-[14px] tw-w-[48%] tw-mb-3 tw-ml-[4%] animate__animated animate__fadeInUp animate_delay-1s tw-z-20 tw-py-[8px]  tw-border-2 tw-border-[#86FF68] tw-bg-[#00000065] tw-font-bold tw-text-[12px] tw-text-[#ffffff] tw-rounded-[10px]'>Security Services</button>
                                <button className='tw-px-[14px] tw-w-[48%] animate__animated animate__fadeInUp animate_delay-1s tw-z-20 tw-py-[8px]  tw-border-2 tw-border-[#86FF68] tw-bg-[#00000065] tw-font-bold tw-text-[12px] tw-text-[#ffffff] tw-rounded-[10px]'>Garden Care</button>
                                <button className='tw-px-[14px] tw-w-[48%] tw-ml-[4%] animate__animated animate__fadeInUp animate_delay-1s tw-z-20 tw-py-[8px]  tw-border-2 tw-border-[#86FF68] tw-bg-[#00000065] tw-font-bold tw-text-[12px] tw-text-[#ffffff] tw-rounded-[10px]'>Maintenance</button>
                            </div>
                            <h5 className='animate__animated animate__fadeInUp animate__delay-1s tw-text-center tw-text-[18px] tw-font-[500] tw-hidden md:tw-block tw-leading-[28px] tw-mt-5'>
                                Transform your space with our expert touch. <br />
                                Ready to shine? Let's begin.
                            </h5>
                            <h5 className='animate__animated animate__fadeInUp animate__delay-2s tw-text-center md:tw-hidden tw-text-[18px] tw-font-[500] tw-leading-[28px] tw-mt-5'>
                                Transform your space with our expert <br />
                                touch. Ready to shine? Let's begin.
                            </h5>
                            <div className='tw-flex tw-justify-center tw-mt-10'>
                                <Link to="/contact-us" className='tw-px-[19px] animate__animated animate__fadeInUp animate_delay-1s tw-z-20 tw-py-[17px] tw-bg-[#86FF68] tw-font-bold tw-text-[16px] tw-rounded-[10px]'>Get a Free Quote</Link>
                            </div>
                        </div>
                    </div>


                </div>
                <div>
                    <div>
                        <img
                            src='assets/images/clean1.png'
                            alt='Cleaning'
                            className='lg:tw-h-[514px] lg:tw-w-[514px] tw-hidden lg:tw-block tw-w-[314px] tw-h-[314px] tw-opacity-[20%] tw-grayscale tw-absolute tw-top-[-20px] md:tw-left-0 tw-left-[-20px]'
                        />
                        <img
                            src='assets/images/clean2.png'
                            alt='Cleaning'
                            className='tw-opacity-[20%] tw-grayscale tw-hidden lg:tw-block tw-absolute md:tw-top-[100px] lg:tw-rotate-12 tw-top-[155px] tw-right-[20px] md:tw-right-[30px]'
                        />
                    </div>
                </div>
            </div>
            {/* <div className='tw-py-12 tw-border-black tw-border-t-4 tw-border-b-4 tw-mt-16 text-banner-trans'>
                <h2 className='tw-text-center lg:tw-text-[96px] tw-text-[48px] tw-font-[400] lg:tw-leading-[80px] tw-leading-[50px] tw-text-[#686868]'>
                    <span className='tw-italic'>Discover</span>
                    <span className='tw-font-[600] trans-text'> the Magic of Cleaning</span>
                </h2>
            </div> */}
            <div className="tw-mx-auto tw-w-[85%] tw-mt-24">
                <Grid>
                    <Grid.Col
                        span={{ sm: 12, md: 12, lg: 6, xl: 6 }}
                        order={{ base: 2, sm: 2, lg: 1, xl: 1 }}>
                        <div className='lg:tw-order-1 lg:tw-pr-12' id='who-we-are'>
                            <h3 className='tw-font-semibold tw-text-[32px]'>Who we are</h3>
                            <p className='tw-text-left tw-mt-3'>
                                OZLINK has been providing high quality, customized commercial cleaning services to businesses across Brisbane and Queensland for over 11 years. Expanding our reach to VIC, TAS, and NT, we bring our expertise to businesses nationwide.

                            </p>
                            <p className='tw-text-left tw-mt-3 tw-mb-4'>
                                Led by Director Charmin Herath, our highly skilled teams adhere to a sustainable business model focused on delivering exceptional service and upholding safety standards.
                            </p>
                            <p className='tw-text-left tw-mt-3 tw-mb-4'>
                                Our organization boasts excellent standards working with businesses across a wide variety of industries, including industrial, retail, office, health, exercise, education, and medical centers. our cleaning teams undergo thorough training and induction processes tailored to each location they serve.
                            </p>
                            <Link to="/about-us" className='tw-underline tw-text-[#D71919] tw-underline-offset-4 tw-font-[500] tw-text-[16px] tw-mt-8'>Read more</Link>
                        </div>

                    </Grid.Col>
                    <Grid.Col
                        span={{ sm: 12, md: 12, lg: 6, xl: 6 }}
                        order={{ base: 1, sm: 1, lg: 2, xl: 2 }}>
                        <div className='tw-flex xl:tw-justify-end tw-justify-center lg:tw-order-2 tw-order-1'>
                            <img src="assets/images/lady-card.webp" alt="Lady" className='tw-rounded-[25px]' />
                        </div>
                    </Grid.Col>
                </Grid>
            </div>
            <div className='tw-w-[85%] tw-mx-auto'>
                <Title fz={24} fw={600} hiddenFrom='md' mt={42}>Trusted Process</Title>
            </div>
            <div className='tw-grid lg:tw-grid-cols-3 tw-grid-cols-1 tw-gap-12 tw-mx-auto tw-w-[85%] tw-mt-8 lg:tw-mt-16'>
                <div>
                    <div className='tw-relative tw-h-[46px] tw-w-[46px] tw-bg-[#E0FFD8] tw-rounded-full tw-p-1'>
                        <h4 className='tw-absolute tw-bottom-0 tw-left-[3px] tw-font-[600] tw-text-[24px]'>01</h4>
                    </div>
                    <div>
                        <h4 className='tw-font-[600] tw-mt-3 tw-text-[18px]'>Assessment & Customization</h4>
                        <Divider size="sm" color='#111' my={6} />
                        <p className='tw-mt-8'>We assess your cleaning needs and customize a plan tailored to your preferences and industry requirements.</p>
                    </div>
                </div>
                <div>
                    <div className='tw-relative tw-h-[46px] tw-w-[46px] tw-bg-[#E0FFD8] tw-rounded-full tw-p-1'>
                        <h4 className='tw-absolute tw-bottom-0 tw-left-[3px] tw-font-[600] tw-text-[24px]'>02</h4>
                    </div>
                    <div>
                        <h4 className='tw-font-[600] tw-mt-3 tw-text-[18px]'>Expert Execution & Quality Assurance</h4>
                        <Divider size="sm" color='#111' my={6} />
                        <p className='tw-mt-8'>Our proficient teams execute tasks with precision, ensuring impeccable results and adhering to strict quality checks.</p>
                    </div>
                </div>
                <div>
                    <div className='tw-relative tw-h-[46px] tw-w-[46px] tw-bg-[#E0FFD8] tw-rounded-full tw-p-1'>
                        <h4 className='tw-absolute tw-bottom-0 tw-left-[3px] tw-font-[600] tw-text-[24px]'>03</h4>
                    </div>
                    <div>
                        <h4 className='tw-font-[600] tw-mt-3 tw-text-[18px]'>Client Satisfaction & Ongoing Support</h4>
                        <Divider size="sm" color='#111' my={6} />
                        <p className='tw-mt-8'>Your contentment drives us. We gather feedback to ensure your needs are met and provide ongoing support for lasting satisfaction.</p>
                    </div>
                </div>
            </div>
            <div className='tw-bg-[#C9FFBB] tw-mt-24 tw-h-fit' id='services'>
                <div className='container tw-mx-auto tw-w-[85%]'>
                    <div className='tw-border-b-2 tw-border-black tw-pb-8'>
                        <Space h="md" />
                        <h2 className='lg:tw-text-[36px] lg:tw-text-left tw-text-[24px] tw-text-center tw-font-[600] tw-mt-8 tw-mb-6'>Industries We Cover</h2>
                        <p className='tw-text-center lg:tw-text-left'>
                            We offer premier cleaning services to diverse clientele spanning various industries. Our adaptable approach allows us to customize our services to meet the unique needs of our customers, regardless of their requirement.
                        </p>
                    </div>
                    <div className="tw-grid lg:tw-grid-cols-2 tw-grid-cols-1 tw-mt-12">
                        <div className='tw-h-fit'>
                            <Image src="assets/images/working.jpg" radius={25} />
                            <div className='tw-p-3 tw-relative tw-justify-center md:tw-flex tw-hidden'>
                                <div className='tw-rounded-[30px] tw-border-2 tw-mt-[-125px] tw-w-[85%] tw-border-black tw-bg-white tw-p-6'>
                                    <h5 className='tw-text-[24px] tw-font-[600]'>Enquire about our services</h5>
                                    <Text>Simply leave your name and a form of contact, and we'll get back to you as soon as possible.</Text>
                                    <form className='tw-mt-8'>
                                        <Stack gap={20}>
                                            <Input radius={10} onChange={(e) => setFormData((prev) => {
                                                return {
                                                    ...prev,
                                                    fullName: e.target.value
                                                }
                                            })} value={formData.fullName} placeholder="Full Name" size="lg" />
                                            <Input radius={10} onChange={(e) => setFormData((prev) => {
                                                return {
                                                    ...prev,
                                                    telephone: e.target.value
                                                }
                                            })} value={formData.telephone} placeholder="Telephone" size="lg" />
                                            <Input radius={10} onChange={(e) => setFormData((prev) => {
                                                return {
                                                    ...prev,
                                                    email: e.target.value
                                                }
                                            })} value={formData.email} placeholder="Email" size="lg" />
                                            <Select
                                                radius={10}
                                                size="lg"
                                                fw={500}
                                                c='#111'
                                                value={formData.serviceType}
                                                onChange={(value) => setFormData((prev) => {
                                                    return {
                                                        ...prev,
                                                        serviceType: value
                                                    }
                                                })}
                                                placeholder="Service Type"
                                                data={[
                                                    'Commercial cleaning',
                                                    'Security services',
                                                    'Garden care',
                                                    'Maintenance'
                                                ]}
                                            />
                                            <Textarea
                                                radius={10}
                                                size='lg'
                                                rows={8}
                                                onChange={(e) => setFormData((prev) => {
                                                    return {
                                                        ...prev,
                                                        msg: e.target.value
                                                    }
                                                })}
                                                value={formData.msg}
                                                placeholder="Message (Optional)"
                                            />
                                            <Button
                                                mt={18}
                                                h={54}
                                                bg={"black"}
                                                fs={16}
                                                onClick={handleFormSubmit}
                                                radius={10}
                                                fullWidth={false}
                                                w={160} >
                                                Send Enquiry
                                            </Button>
                                        </Stack>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className='tw-relative lg:tw-p-24 lg:tw-pt-6'>
                            <img src='assets/images/brush3.svg' alt='Brush' className='tw-absolute tw-right-0 tw-bottom-0' />
                            <div className='tw-mb-8 tw-border-b-2 tw-border-black tw-pb-6 lg:tw-mt-0 tw-mt-12'>
                                <h5 className='tw-mb-3 tw-text-lg tw-font-[600]'>Industrial Sites</h5>
                                <Text>Our specialized services cater to plants, factories, and other industrial spaces, with careful compliance with the strict safety regulations of industrial standards.</Text>
                            </div>
                            <div className='tw-mb-8 tw-border-b-2 tw-border-black tw-pb-6'>
                                <h5 className='tw-mb-3 tw-text-lg tw-font-[600]'>Office Spaces</h5>
                                <Text>We offer well-established office cleaning methods, creating sustainable and clean environments for your employees.</Text>
                            </div>
                            <div className='tw-mb-8 tw-border-b-2 tw-border-black tw-pb-6'>
                                <h5 className='tw-mb-3 tw-text-lg tw-font-[600]'>
                                    Shopping centres and Retails shops
                                </h5>
                                <Text>
                                    Ozlink offers a wide variety of high-quality retail shopping center cleaning service. We have
                                    extensive experience in servicing sites within these sectors.  Our retail and shopping centre
                                    cleaning plans are designed to work behind-the-scenes to help give your retail space the best possible experience.
                                </Text>
                            </div>
                            <div className='tw-mb-8 tw-border-b-2 tw-border-black tw-pb-6'>
                                <h5 className='tw-mb-3 tw-text-lg tw-font-[600]'>Medical centers</h5>
                                <Text>At OZLINK, we specialize in providing highest standards of cleanliness and hygiene for medical centers. Our dedicated team follows strict hospital-grade regulations to create safe and sterile environments for patients, staff, and visitors.</Text>
                            </div>
                            <div className='tw-mb-8 tw-border-b-2  tw-pb-6'>
                                <h5 className='tw-mb-3 tw-text-lg tw-font-[600]'>Educational Institutes</h5>
                                <Text>Our team understands the importance of providing a safe and healthy environment for students, faculty, and staff. We employ industry-leading practices to ensure effective cleaning without compromising the well-being of students and staff. From classrooms to common areas, we strive to create safe learning environments where hygiene is kept at its best.</Text>
                            </div>
                            {/* <div className='tw-mb-8 tw-border-b-2 tw-border-black tw-pb-6'>
                                <h5 className='tw-mb-3 tw-text-lg tw-font-[600]'>Maintenance services</h5>
                                <Text>Our organisation delivers services  to hospitality centres, building our  team’s skills to create with diverse  cleaning solutions in the face of  hazardous materials and in  compliance with site WHS  regulations.</Text>
                            </div>
                            <div className='tw-mb-8 tw-pb-6'>
                                <h5 className='tw-mb-3 tw-text-lg tw-font-[600]'>Security services</h5>
                                <Text>We service plants, factories, and   other industrial spaces with careful   compliance to the strict safety   regulations these locations present.</Text>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='tw-container tw-w-[85%] tw-mx-auto'>
                <Space h={55} />
                <div className='tw-py-4'>
                    <Title className='tw-text-center' mb={4}>News</Title>
                    <h6 className='tw-text-center tw-font-[600]'>Discover the Latest in Cleaning Solutions</h6>
                </div>
                <Space h={20} />
                <div className="tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-grid-cols-1 tw-gap-6">
                    {newsCards.map((card) => {
                        return (
                            <Card
                                shadow="sm"
                                key={card.id}
                                padding="lg"
                                mb={6}
                                h={{ xl: 540, lg: 540, md: 620, sm: 600 }}
                                radius={20}>
                                <Card.Section>
                                    <Image
                                        src={card.image}
                                        height={160}
                                        alt="Card Image"
                                    />
                                </Card.Section>
                                <Group style={{ height: "100%" }} justify="space-between" mt="md" mb="xs">
                                    <Text
                                        fw={600}
                                        fz={{ base: 16 }}
                                        c={"#461058"} >{card.title}</Text>
                                    <div className='tw-h-[1px] tw-bg-black tw-w-full'></div>
                                    <Text>
                                        {card.description}
                                    </Text>
                                    <Anchor
                                        href={`/news/${card.slug}`}
                                        c="#D82323"
                                        target="_blank">
                                        Read more
                                    </Anchor>
                                </Group>
                            </Card>
                        );
                    })}
                </div>
            </div>
            <div className='tw-container tw-w-[85%] tw-mx-auto lg:tw-mt-32 tw-mt-16 lg:tw-mb-10' id='faq'>
                <Grid>
                    <Grid.Col
                        span={{ sm: 12, md: 12, lg: 6, xl: 6 }}
                        order={{ base: 2, sm: 2, lg: 1, xl: 1 }}>
                        <Title fw={600} fz={{ base: 24, lg: 32 }} visibleFrom='lg'>Frequently Asked Question</Title>
                        <Accordion
                            mt={18}
                            defaultValue="q1">
                            {freQuestions}
                        </Accordion>
                    </Grid.Col>
                    <Grid.Col
                        style={{ position: "relative", height: "fit-content" }}
                        span={{ sm: 12, md: 12, lg: 6, xl: 6 }}
                        order={{ base: 1, sm: 1, lg: 2, xl: 2 }}>
                        <div>
                            <Title
                                fw={600}
                                style={{ textAlign: "center" }}
                                fz={{ base: 24, lg: 32 }}
                                hiddenFrom='lg'
                                mb={24}>Frequently Asked Question</Title>
                        </div>
                        <div className='lg:tw-block tw-flex tw-justify-center'>
                            <img
                                alt='Cleaning Lady'
                                className='lg:tw-w-[85%] tw-w-full tw-ml-auto tw-rounded-[25px]'
                                src="assets/images/freq-lady.jpg" />
                        </div>
                        <div className='lg:tw-w-[430px] tw-w-[300px] tw-rounded-[20px] tw-bg-[#1E1D4A] tw-p-4 tw-absolute lg:tw-bottom-[100px] md:tw-bottom-[20px] tw-bottom-[30px] lg:tw-right-[-42px] md:tw-right-[-20px] sm:tw-right-[-12px] tw-right-[-5px]'>
                            <Text
                                c="white"
                                fz={{ xl: 14, lg: 14, md: 12, sm: 12, base: 12 }}
                                fs={"italic"}>
                                If you're looking for the best cleaning service in your area, turn to OZLINK. Trusted across Australia, we deliver reliable and top-quality cleaning solutions for homes and businesses alike.
                            </Text>
                            <Group justify='space-between' mt={8}>
                                <Rating defaultValue={4} size="xs" color='#86FF68' />
                                <Text
                                    fz={{ xl: 14, lg: 14, md: 12, sm: 12, base: 10 }}
                                    c="#86FF68"
                                >96% of clients recommend our service</Text>
                            </Group>
                            <Link to={"/contact-us"}>
                                <Button
                                    radius={8}
                                    fz={12}
                                    visibleFrom='sm'
                                    mt={12}
                                    className='tw-hidden lg:tw-visible'
                                    c={"black"}
                                    size="xs"
                                    bg={"#86FF68"}>Enquire about your service</Button>
                            </Link>
                        </div>
                    </Grid.Col>
                </Grid>
            </div>
        </div>
    )
};
