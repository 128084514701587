import React, { useEffect, useState } from 'react';
import {
    Image,
    Title,
    Text,
    Group,
    Anchor
} from '@mantine/core';
import { latestNews } from '../dummy';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';

export default function NewsReadMore() {
    const [news, setNews] = useState(null);
    const [newsContent, setNewsContent] = useState("");

    const { slug } = useParams();

    async function initData() {
        // Fetch news data from API
        if (slug) {

            const newsData = latestNews.filter((nw) => nw.slug === slug)[0];
            const sanatizeContent = DOMPurify.sanitize(newsData.content);

            setNewsContent(sanatizeContent);
            setNews(newsData);

        }
    }

    useEffect(() => {
        initData();
    }, []);

    return (
        <div>
            <div className='tw-w-[85%] tw-mx-auto'>
                {news ?
                    <>
                        <div>
                            <Title
                                style={{
                                    textAlign: "center"
                                }}
                                my={20}
                                fw={600}
                                fz={{ lg: 64, base: 32 }}>
                                {news.title}
                            </Title>
                        </div>
                        <div>
                            <Image src={`/${news.full_image}`} h={{ lg: 549, base: 312 }} radius={40} />
                        </div>
                        <div className='tw-mt-12'>
                            <div dangerouslySetInnerHTML={{ __html: newsContent }} />
                        </div>
                        <div className='tw-mt-14'>
                            <Group justify='flex-start'>
                                <Text fw={600}>Share on</Text>
                                <Group
                                    justify='flex-start'
                                    gap={3}
                                    ml={12}
                                    w={"160px"}>
                                    <div>
                                        <Anchor href='https://facebook.com' target='_blank'>
                                            <img src="/assets/images/fb-dark.svg" alt="Fb" />
                                        </Anchor>
                                    </div>
                                    <div className='tw-ml-3'>
                                        <Anchor href='https://instagram.com' target='_blank'>
                                            <img src="/assets/images/insta-dark.svg" alt="Instagram" />
                                        </Anchor>
                                    </div>
                                    <div className='tw-ml-3'>
                                        <Anchor href='https://linkedin.com' target='_blank'>
                                            <img src="/assets/images/linkedin-dark.svg" alt="LinkedIn" />
                                        </Anchor>
                                    </div>
                                </Group>
                            </Group>
                        </div>
                    </> : null}
            </div>
        </div>
    )
}
