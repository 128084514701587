import React from 'react';
import {
  Anchor,
  Button,
  Grid,
  Group,
  Stack,
  Text,
  Title
} from '@mantine/core';
import { Link } from 'react-router-dom';
import { FaceBook, LinkedIn } from './icons';

export default function MainFooter() {
  return (
    <footer>
      {/* <div className='lg:tw-h-[300px] tw-h-[370px] tw-bg-[#C9FFBB] tw-mt-16 tw-relative'>
        <img src="/assets/images/dots-bg.svg" className='tw-absolute tw-left-1 tw-h-[300px] tw-w-[280px] md:tw-w-auto' alt="Dots" />
        <img src="/assets/images/clean4.svg" className='tw-absolute tw-right-1 tw-h-[300px] tw-bottom-[-30px] lg:tw-top-2' alt="Clean Dots" />
        <div className='tw-w-[85%] tw-mx-auto tw-flex tw-items-center tw-h-full'>
          <div>
            <Group justify='space-between' align="center">
              <Title
                fw={600}
                fz={{ xl: 96, lg: 96, sm: 46, base: 46 }}
                lh={{ xl: "100px", lg: "100px", md: "50px", sm: "50px", base: "50px" }}
                c={"#434343"}
              >
                Looking for a
              </Title>
              <img className='lg:tw-block tw-hidden' src="/assets/images/arrow.svg" alt="Arrow Icon" />
              <Link to={"/contact-us"}>
                <Button
                  size="xl"
                  px={30}
                  style={{
                    border: "4px solid #434343"
                  }}
                  visibleFrom='lg'
                  radius={30}
                  c={"#434343"}
                  variant="outline">
                  Contact us
                </Button>
              </Link>
            </Group>
            <Group justify='flex-start'>
              <Title
                fw={600}
                fz={{ xl: 96, lg: 96, sm: 46, base: 46 }}
                lh={{ xl: "100px", lg: "100px", md: "50px", sm: "50px", base: "50px" }}
                c={"#434343"}
              >domestic</Title>
              <Title
                fs="italic"
                fw={300}
                c={"#434343"}
                fz={{ xl: 76, lg: 76, sm: 46, base: 46 }}
                lh={{ xl: "100px", lg: "100px", md: "50px", sm: "50px", base: "50px" }}
              >cleaning company?</Title>
            </Group>
            <Link to={"/contact-us"}>
              <Button
                size="md"
                px={20}
                style={{
                  border: "2px solid #434343"
                }}
                mt={28}
                fz={16}
                hiddenFrom='lg'
                radius={30}
                c={"#434343"}
                variant="outline">
                Contact us
              </Button>
            </Link>
          </div>
        </div>
      </div> */}
      <div className='tw-bg-black tw-text-white lg:tw-p-28 tw-border-b-2 tw-mt-16 tw-border-[#FFFEFC]'>
        <Grid visibleFrom='md'>
          <Grid.Col span={{ lg: 5, base: 12 }} p={{ base: 32 }}>
            <Stack gap={20}>
              <img
                className='tw-w-[55px] tw-h-[55px]'
                src="/assets/images/logo.png"
                alt="" />
              <Text fz={16} fw={400}>
                Ozlink has been providing high quality, customised commercial
                cleaning services to businesses across Brisbane and
                Queensland for over 11 years.
              </Text>
              <Link to="/contact-us">
                <Button
                  fullWidth={false}
                  fz={16}
                  w={170}
                  h={50}
                  style={{
                    border: "2px solid #86FF68"
                  }}
                  variant='outline'
                  radius={10}
                  c="#86FF68">
                  Get a Free Quote
                </Button>
              </Link>
            </Stack>
          </Grid.Col>
          <Grid.Col span={{ lg: 2, base: 12 }} p={{ base: 32 }}>
            <Title fz={16}>
              Quick Links
            </Title>
            <Stack gap={8} mt={20}>
              <a href="/" className='tw-w-fit tw-text-[16px]'>Home</a>
              <a href="/about-us" className='tw-w-fit tw-text-[16px]'>About Us</a>
              <a href="/#services" className='tw-w-fit tw-text-[16px]'>Services</a>
              <a href="/news" className='tw-w-fit tw-text-[16px]'>News</a>
              <a href="/contact-us" className='tw-w-fit tw-text-[16px]'>Contact Us</a>
            </Stack>
          </Grid.Col>
          <Grid.Col span={{ lg: 3, base: 12 }} p={{ base: 32 }}>
            <Title fz={16}>
              Contact us
            </Title>
            <Stack gap={8} mt={20}>
              <Anchor
                fz={16}
                style={{ color: "#fff" }}
                href='tel:+61733372212' >
                +61 73 3372 212
              </Anchor>
              <Anchor
                fz={16}
                style={{ color: "#fff" }}
                href='mail:admin@ozlinkfs.com.au' >
                admin@ozlinkfs.com.au
              </Anchor>
            </Stack>
          </Grid.Col>
          <Grid.Col span={{ lg: 2, base: 12 }} p={{ base: 32 }}>
            <Group justify='flex-start'>
              <Title fz={16}>
                Follow us on
              </Title>
              <div className='tw-flex tw-w-full'>
                <div>
                  <Anchor href='https://web.facebook.com/ozlinkcleaningservices/?_rdc=1&_rdr' target='_blank'>
                    <FaceBook />
                  </Anchor>
                </div>

                <div className='tw-ml-3'>
                  <Anchor href='https://www.linkedin.com/company/ozlink-facility-services/' target='_blank'>
                    <LinkedIn />
                  </Anchor>
                </div>
              </div>
            </Group>
          </Grid.Col>
        </Grid>
      </div>
      <div className='tw-bg-black tw-text-white lg:tw-p-28 tw-border-b-2 tw-border-[#FFFEFC] lg:tw-hidden'>
        <div>
          <div className='tw-p-8'>
            <Stack gap={20}>
              <Title>OZ Link</Title>
              <Text fz={16} fw={400}>
                Ozlink has been providing high quality, customised commercial
                cleaning services to businesses across Brisbane and
                Queensland for over 11 years.
              </Text>
              <Link to="/contact-us">
                <Button
                  fullWidth={false}
                  fz={16}
                  w={170}
                  h={50}
                  style={{
                    border: "2px solid #86FF68"
                  }}
                  variant='outline'
                  radius={10}
                  c="#86FF68">
                  Get a Free Quote
                </Button>
              </Link>
            </Stack>
          </div>
          <div className='tw-p-8'>
            <Title fz={16}>
              Quick Links
            </Title>
            <Stack gap={8} mt={20}>
              <a href="/" className='tw-w-fit tw-text-[16px]'>Home</a>
              <a href="/about-us" className='tw-w-fit tw-text-[16px]'>About Us</a>
              <a href="/#services" className='tw-w-fit tw-text-[16px]'>Services</a>
              <a href="/news" className='tw-w-fit tw-text-[16px]'>News</a>
              <a href="/contact-us" className='tw-w-fit tw-text-[16px]'>Contact Us</a>
            </Stack>
          </div>
          <div className='tw-p-8'>
            <Title fz={16}>
              Contact us
            </Title>
            <Stack gap={8} mt={20}>
              <Anchor
                fz={16}
                style={{ color: "#fff" }}
                href='tel:+61733372212' >
                +61 73 3372212
              </Anchor>
              <Anchor
                fz={16}
                style={{ color: "#fff" }}
                href='mail:admin@ozlinkfs.com.au' >
                admin@ozlinkfs.com.au
              </Anchor>
            </Stack>
          </div>
          <div className='tw-p-8'>
            <Group justify='flex-start'>
              <Title fz={16}>
                Follow us on
              </Title>
              <div className='tw-flex tw-w-full'>
                <div>
                  <Anchor href='https://web.facebook.com/ozlinkcleaningservices/?_rdc=1&_rdr' target='_blank'>
                    <FaceBook />
                  </Anchor>
                </div>
                <div className='tw-ml-3'>
                  <Anchor href='https://www.linkedin.com/company/ozlink-facility-services/' target='_blank'>
                    <LinkedIn />
                  </Anchor>
                </div>
              </div>
            </Group>
          </div>
        </div>
      </div>
      <div className='tw-bg-black tw-justify-center tw-h-[60px] tw-flex tw-items-end tw-pb-3'>
        <Text c={"#fff"} fz={14}>© 2024 Ozlink Facility Services PTY LTD. All Rights Reserved.</Text>
      </div>
    </footer>
  )
}
