import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import MainFooter from '../components/ui/MainFooter';
import {
    AppShell,
    Burger,
    Group,
    UnstyledButton,
    Title,
    Loader,
    Text,
    Button,
    Anchor,
    ActionIcon,
} from '@mantine/core';
import {
    IconPhone,
    IconSquareRoundedArrowUpFilled
} from '@tabler/icons-react';
import { useLocation } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import "animate.css/animate.min.css";

import classes from './MobileNavbar.module.css';

export default function MainLayout() {
    const [opened, { toggle }] = useDisclosure();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
    }, []);

    useEffect(() => {
        scrollToTop();
    });

    const location = useLocation();
    const pathname = location.pathname;

    // Function to scroll to top
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <>
            {loading ?
                <>
                    <div className='tw-h-[100vh] tw-flex tw-justify-center tw-items-center'>
                        <Loader color="green" size="sm" />
                    </div>
                </>
                : <AppShell
                    style={{ backgroundColor: "#F5F5F5" }}
                    navbar={{ width: 300, breakpoint: 'md', collapsed: { desktop: true, mobile: !opened } }}
                    padding="md"
                >
                    <AppShell.Header
                        style={{
                            position: 'sticky',
                            borderBottom: "3px solid",
                            backgroundColor: "#F5F5F5"
                        }}>
                        <div className='tw-mx-auto xl:tw-w-[85%] sm:tw-w-[100%]'>
                            <Group h="100%" px="md" py="md">
                                <Group justify="space-between" style={{ flex: 1 }} w={"100%"}>
                                    <Link to={"/"}>
                                        <img
                                            className='tw-w-[45px] tw-h-[45px]'
                                            src="/assets/images/logo.png"
                                            alt="OZ Link Logo" />
                                    </Link>
                                    <Group ml="xl" gap={26} visibleFrom="md">
                                        <UnstyledButton className={pathname === "/" ? classes.control : classes.animatedcontrol}>
                                            <Link to="/"><Text fw={500} fz={16}>Home</Text></Link>
                                        </UnstyledButton>
                                        <UnstyledButton className={pathname === "/about-us" ? classes.control : classes.animatedcontrol}>
                                            <Link to="/about-us"><Text fw={500} fz={16}>About us</Text></Link>
                                        </UnstyledButton>
                                        <UnstyledButton className={pathname === "/#services" ? classes.control : classes.animatedcontrol}>
                                            <a href="/#services"><Text fw={500} fz={16}>Services</Text></a>
                                        </UnstyledButton>
                                        <UnstyledButton className={pathname === "/#faq" ? classes.control : classes.animatedcontrol}>
                                            <a href="/#faq"><Text fw={500} fz={16}>FAQ</Text></a>
                                        </UnstyledButton>
                                        <UnstyledButton className={pathname === "/#testimonials" ? classes.control : classes.animatedcontrol}>
                                            <a href="/about-us/#testimonials"><Text fw={500} fz={16}>Testimonials</Text></a>
                                        </UnstyledButton>
                                        <UnstyledButton className={pathname === "/news" ? classes.control : classes.animatedcontrol}>
                                            <Link to="/news"><Text fw={500} fz={16}>News</Text></Link>
                                        </UnstyledButton>
                                    </Group>
                                    <Anchor href='tel:+61733372212'>
                                        <Button
                                            radius={10}
                                            px={{ lg: 15, md: 15, base: 12 }}
                                            bg="#111"
                                            h={40}
                                            variant="filled">
                                            <span className='tw-flex tw-items-center tw-font-light'>
                                                <IconPhone size={18} /> <span className='tw-ml-2'>+61 73 3372212</span>
                                            </span>
                                        </Button>
                                    </Anchor>
                                    <Burger
                                        opened={opened}
                                        onClick={toggle}
                                        hiddenFrom="md"
                                        size="sm"
                                        h={20}
                                    />
                                </Group>
                            </Group>
                        </div>
                    </AppShell.Header>
                    <AppShell.Navbar py="md" px={12} pt={8}>
                        <Group justify='space-between' p={3} mb={8}>
                            <Link to={"/"}>
                                <img
                                    className='tw-w-[55px] tw-h-[55px]'
                                    src="/assets/images/logo.png"
                                    alt="OZ Link Logo" />
                            </Link>
                            <Burger
                                opened={opened}
                                onClick={toggle}
                                hiddenFrom="md"
                                size="sm"
                                h={20}
                                style={{ zIndex: 99999 }} />
                        </Group>
                        <UnstyledButton style={{ borderBottom: "1px solid #ccc" }} pb={28} mb={20} onClick={toggle} className={classes.controlMobile}>
                            <Link to="/"><Text fw={500} fz={16}>Home</Text></Link>
                        </UnstyledButton>
                        <UnstyledButton style={{ borderBottom: "1px solid #ccc" }} pb={28} mb={20} onClick={toggle} className={classes.controlMobile}>
                            <Link to="/about-us"><Text fw={500} fz={16}>About us</Text></Link>
                        </UnstyledButton>
                        <UnstyledButton style={{ borderBottom: "1px solid #ccc" }} pb={28} mb={20} onClick={toggle} className={classes.controlMobile}>
                            <a href="/#services"><Text fw={500} fz={16}>Services</Text></a>
                        </UnstyledButton>
                        <UnstyledButton style={{ borderBottom: "1px solid #ccc" }} pb={28} mb={20} onClick={toggle} className={classes.controlMobile}>
                            <a href="/#faq"><Text fw={500} fz={16}>FAQ</Text></a>
                        </UnstyledButton>
                        <UnstyledButton style={{ borderBottom: "1px solid #ccc" }} pb={28} mb={20} onClick={toggle} className={classes.controlMobile}>
                            <a href="/about-us/#testimonials"><Text fw={500} fz={16}>Testimonials</Text></a>
                        </UnstyledButton>
                        <UnstyledButton style={{ borderBottom: "1px solid #ccc" }} pb={28} mb={20} onClick={toggle} className={classes.controlMobile}>
                            <Link to="/news"><Text fw={500} fz={16}>News</Text></Link>
                        </UnstyledButton>
                    </AppShell.Navbar>
                    <Outlet />
                    <MainFooter />
                </AppShell>}
            <ActionIcon
                bg={"#86ff68"}
                onClick={scrollToTop}
                style={{
                    position: "fixed",
                    bottom: "20px",
                    right: "20px",
                    width: "45px",
                    height: "45px",
                    zIndex: 9999999
                }}>
                <IconSquareRoundedArrowUpFilled fontSize={50} />
            </ActionIcon>
        </>

    )
};
