const latestNews = [
    {
        id: 1,
        title: "Benefits of Professional Commercial Cleaning Services",
        slug: "benefits-of-commercial-cleaning-services",
        image: "assets/images/news2.webp",
        description: "In today's competitive business landscape, maintaining a clean and hygienic workplace is essential for success.",
        full_image: "assets/images/news2.webp",
        content: `In today's competitive business landscape, maintaining a clean and hygienic workplace is
        essential for success. While some businesses may attempt to handle cleaning tasks in-house,
        there are numerous benefits to outsourcing these responsibilities to professional commercial
        cleaning services like OZLINK. Let's explore five key advantages of partnering with a
        professional cleaning company: <br> <br>
        1. Improved Health and Safety: A clean workplace promotes a healthy environment for
        employees and visitors by reducing the risk of illness and injury. Professional cleaners
        use industry-standard equipment and techniques to ensure thorough sanitation and
        hygiene, minimizing the spread of germs and pathogens. <br> <br>
        2. Enhanced Productivity: A clean and organized workspace can have a positive impact
        on employee morale and productivity. Studies have shown that employees are more
        motivated and focused in a clean environment, leading to higher levels of productivity
        and efficiency.<br> <br>
        3. Cost Savings: Outsourcing cleaning tasks to a professional company can result in cost
        savings for businesses. By eliminating the need to hire and manage in-house cleaning
        staff, businesses can reduce labor costs, equipment expenses, and overhead
        associated with cleaning operations.<br> <br>
        4. Expertise and Experience: Professional cleaning companies like OZLINK have the
        expertise and experience to handle a wide range of cleaning tasks efficiently and
        effectively. Their trained and skilled staff are familiar with the latest cleaning techniques
        and best practices, ensuring optimal results every time.<br> <br>
        5. Flexibility and Customization: Professional cleaning services offer flexibility and
        customization to meet the unique needs of each client. Whether it's daily, weekly, or
        monthly cleaning schedules, or specific cleaning requirements for different areas of the
        workplace, professional cleaners can tailor their services to accommodate individual
        preferences and schedules.<br> <br>
        In conclusion, investing in professional commercial cleaning services offers numerous benefits
        for businesses, including improved health and safety, enhanced productivity, cost savings,
        expertise and experience, and flexibility and customization. By partnering with a reputable
        cleaning company like OZLINK, businesses can maintain a clean, healthy, and welcoming
        environment that fosters success and growth.`,
    },
    {
        id: 2,
        title: "The Importance of Sustainable Cleaning Practices in the Workplace",
        slug: "importance-of-sustainable-cleaning-practices",
        image: "assets/images/news4.webp",
        description: "In recent years, there has been a growing awareness of the environmental impact of traditional cleaning products and practices.",
        full_image: "assets/images/news4.webp",
        content: `In recent years, there has been a growing awareness of the environmental impact of traditional
        cleaning products and practices. Businesses are increasingly recognizing the importance of
        adopting sustainable cleaning practices to minimize their carbon footprint and promote
        environmental responsibility. Here are several reasons why sustainable cleaning practices are
        crucial in the workplace: <br><br>
        1. Reduced Environmental Impact: Traditional cleaning products often contain harmful
        chemicals and toxins that can pollute the air and waterways and contribute to
        environmental degradation. By switching to eco-friendly cleaning products and practices,
        businesses can minimize their impact on the environment and help preserve natural
        resources for future generations. <br><br>
        2. Improved Indoor Air Quality: Many conventional cleaning products emit volatile organic
        compounds (VOCs) and other harmful pollutants that can compromise indoor air quality
        and pose health risks to occupants. Eco-friendly cleaning products are formulated with
        natural, non-toxic ingredients that are safer for indoor air quality and human health. <br><br>
        3. Healthier Work Environment: Sustainable cleaning practices contribute to a healthier and
        safer work environment for employees. By reducing exposure to harmful chemicals and
        toxins, businesses can mitigate the risk of respiratory problems, allergies, and other
        health issues associated with indoor air pollution. <br><br>
        4. Enhanced Corporate Image: Adopting sustainable cleaning practices demonstrates a
        commitment to environmental stewardship and corporate social responsibility.
        Businesses that prioritize sustainability are viewed more favorably by consumers,
        employees, and stakeholders, enhancing their reputation and competitive advantage in
        the marketplace.<br><br>
        5. Cost Savings: While some businesses may assume that eco-friendly cleaning products
        are more expensive than their conventional counterparts, sustainable cleaning practices
        can actually result in long-term cost savings. By reducing energy consumption, water
        usage, and waste generation, businesses can lower operational costs and improve their
        bottom line.<br><br>
        In conclusion, sustainable cleaning practices play a vital role in creating a healthier, safer, and
        more environmentally responsible workplace. By embracing eco-friendly cleaning products and
        practices, businesses can minimize their environmental impact, enhance indoor air quality,
        promote employee health and well-being, enhance their corporate image, and achieve longterm cost savings. OZLINK is committed to promoting sustainability in the workplace and offers
        eco-friendly cleaning solutions tailored to the unique needs of each client.`,
    },
    {
        id: 3,
        title: "Embracing the Ease of Professional Cleaning Services",
        slug: "professional-cleaning-services",
        image: "assets/images/news3.webp",
        description: "In a world that's constantly bustling with activity, maintaining a clean and tidy living or working space can sometimes feel like an insurmountable challenge. ",
        full_image: "assets/images/news3.webp",
        content: `In the whirlwind of modern life, where schedules are packed and time is a precious commodity, the task of cleaning can often fall by the wayside. Yet, the importance of a clean environment cannot be overstated. It not only enhances the aesthetic appeal of a space but also promotes better health and well-being. This is where professional cleaning services step in, offering a lifeline to those grappling with the demands of daily life. <br><br>

        One of the most compelling aspects of professional cleaning services is their ability to customize solutions to suit individual needs. Whether you're a busy professional seeking a weekly house cleaning service or a business owner in need of regular office maintenance, these services can be tailored to fit seamlessly into your routine. This flexibility ensures that you receive the level of cleanliness you desire without disrupting your schedule. <br><br>
        
        Moreover, professional cleaners bring expertise and efficiency to the table. Armed with specialized tools, industry knowledge, and effective cleaning techniques, they tackle dirt, grime, and dust with precision. From deep cleaning carpets and upholstery to sanitizing kitchens and bathrooms, they leave no corner untouched, ensuring a thorough and comprehensive clean every time. <br><br>
        
        Beyond the physical aspect of cleaning, there's a psychological benefit to enlisting professional help. The peace of mind that comes with knowing your space is being cared for by skilled professionals is invaluable. It alleviates stress and frees up mental bandwidth, allowing you to focus on more pressing matters or simply indulge in much-needed relaxation. <br><br>
        
        For businesses, investing in professional cleaning services can also yield tangible benefits. A clean and well-maintained workspace not only fosters a positive impression among clients and visitors but also boosts employee morale and productivity. It creates an environment conducive to success, where individuals can thrive and excel. <br><br>
        
        In essence, professional cleaning services offer more than just a tidy space; they provide a gateway to a better quality of life. By outsourcing the chore of cleaning, you reclaim precious time and energy to devote to the things that truly matter. So, whether you're a busy professional, a harried parent, or a business owner striving for excellence, consider the transformative magic of professional cleaning services. It's an investment in both cleanliness and peace of mind, and the results speak for themselves.`
    }
];

const newsCards = [
    {
        id: 1,
        title: "Asipiya now offering onsite consultants in the Nordics",
        description: "Asipiyaoffice at Sri Lanka  is officially open,and we are thrilled to be expanding our presence in our main customer market, SriLanka.",
        image: "assets/images/cover-img.webp"
    },
    {
        id: 2,
        title: "Benefits of Professional Commercial Cleaning Services",
        description: `In today's competitive business landscape, maintaining a clean and hygienic workplace is
        essential for success.`,
        image: "assets/images/lady-card.webp"
    },
    {
        id: 3,
        title: "Asipiya now offering onsite consultants in the Nordics",
        description: "Asipiyaoffice at Sri Lanka  is officially open,and we are thrilled to be expanding our presence in our main customer market, SriLanka.",
        image: "assets/images/cover-img.webp"
    },
];

const questions = [
    {
        id: 1,
        value: "q1",
        title: "What cleaning services does OZLINK offer?",
        answer: "OZLINK offers a comprehensive range of commercial cleaning services tailored to your specific needs, including office cleaning, industrial site cleaning, medical center cleaning, educational institute cleaning, and more. From general cleaning, deep cleaning and sanitization we tailor our services to meet your unique requirements with precision and care."
    },
    {
        id: 2,
        value: "q2",
        title: "How do you ensure that your cleaning services meet my requirements?",
        answer: "We believe in taking a personalized approach to every client we serve. Before we begin any cleaning project, our team conducts a thorough consultation to understand your needs, preferences, and expectations. From there, we develop a customized cleaning plan specifically tailored to address your unique requirements. Our goal is not just to meet your expectations but to surpass them, leaving your space cleaner and healthier than ever before."
    },
    {
        id: 3,
        value: "q3",
        title: "How does OZLINK ensure that it is a legitimate and trustworthy company?",
        answer: "OZLINK is fully licensed, insured, and compliant with all industry regulations. Our team undergoes rigorous training and vetting processes to ensure professionalism and reliability. You can trust us to deliver on our promises and provide transparent and accountable service."
    },
    {
        id: 4,
        value: "q4",
        title: "What sets OZLINK apart from other cleaning companies in the industry?",
        answer: "What distinguishes OZLINK is our unwavering dedication to providing exceptional service and our willingness to go the extra mile for our clients. We don't believe in second chances – we get it right the first time, every time."
    },
    {
        id: 5,
        value: "q5",
        title: "Can OZLINK accommodate customized schedules?",
        answer: "Absolutely. OZLINK offers flexible scheduling options to meet unique cleaning needs, whether daily, weekly, bi-weekly, or monthly."
    },
    {
        id: 6,
        value: "q6",
        title: "How can I request a quote from OZLINK?",
        answer: "Requesting a quote is easy. Simply fill out our online form, call, or email us with your cleaning requirements, and our team will assist promptly."
    }
];

export { latestNews }
export { newsCards }
export { questions }