import React, { useEffect, useState } from 'react';
import {
    Card,
    Grid,
    Title,
    Image,
    Anchor,
    Group,
    Divider,
    Text
} from '@mantine/core';
import { Link } from 'react-router-dom';

// Mock data for news
import { latestNews } from '../dummy';

export default function News() {
    const [news, setNews] = useState([]);

    async function initData() {
        // Fetch news data from API
        setNews(latestNews);
    }

    useEffect(() => {
        initData();
    }, []);

    return (
        <div className='tw-w-[85%] tw-mx-auto'>
            <div>
                <div>
                    <Title
                        style={{ textAlign: "center" }}
                        fw={600}
                        my={40}
                        fz={{ base: 36, lg: 64, xl: 64 }}>
                        Latest News
                    </Title>
                </div>
                <div>
                    <Grid gutter={32}>
                        {news.map((news) => {
                            return (
                                <Grid.Col span={{ xl: 4, lg: 4, md: 6, sm: 6, base: 12 }}>
                                    <Card
                                        shadow="sm"
                                        key={news.id}
                                        h={{ xl: 540, lg: 540, md: 620, sm: 600 }}
                                        padding="lg"
                                        style={{
                                            borderRadius: "15px"
                                        }}>
                                        <Card.Section>
                                            <Image
                                                src={news.image}
                                                height={160}
                                                alt="Norway"
                                            />
                                        </Card.Section>
                                        <Group style={{ height: "100%" }} justify="space-between" mt="md" mb="xs">
                                            <Text fw={600} fz={18} c={"#461058"}>{news.title}</Text>
                                            <div className='tw-h-[1px] tw-bg-black tw-w-full'></div>
                                            <Text>
                                                {news.description}
                                            </Text>
                                            <Link
                                                style={{
                                                    color: "#D82323"
                                                }}
                                                to={`/news/${news.slug}`}>
                                                Read more
                                            </Link>
                                        </Group>
                                    </Card>
                                </Grid.Col>
                            )
                        })
                        }
                        <Grid.Col></Grid.Col>
                    </Grid>
                </div>
            </div>
        </div>
    )
}
