import React from "react";
import { MantineProvider, createTheme } from "@mantine/core";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import './pages/pages.css';

// Pages
import Home from "./pages/Home";
import About from "./pages/About";
import MainLayout from "./layouts/MainLayout";
import News from "./pages/News";
import NewsReadMore from "./pages/NewsReadMore";
import ContactUs from "./pages/ContactUs";

const theme = createTheme({
  theme: 'light',
  fontFamily: 'Inter',
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/about-us",
        element: <About />
      },
      {
        path: "/news",
        element: <News />
      },
      {
        path: "/contact-us",
        element: <ContactUs />
      },
      {
        path: "/news/:slug",
        element: <NewsReadMore />
      }
    ]
  }
]);

function App() {
  return (
    <MantineProvider theme={theme}>
      <RouterProvider router={router} />
    </MantineProvider>
  );
}

export default App;
