import React, { useState } from 'react';
import {
    Text,
    Title,
    Image,
    Stack,
    Input,
    Button,
    Select,
    Textarea,
    Autocomplete
} from "@mantine/core";

export default function ContactUs() {
    const [formType, setFormType] = useState("enquiry");
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        telephone: '',
        serviceType: '',
        msg: ''
    });

    async function handleFormSubmit() {
        // Submit form
    }

    return (
        <div>
            <div className='tw-w-[85%] tw-mx-auto tw-relative'>
                <div>
                    <img
                        className='tw-absolute tw-left-[-100px] tw-top-[-75px]'
                        src='assets/images/brush.svg'
                        alt='Clean brush' />
                    <div className='tw-mt-12'>
                        <Title
                            style={{
                                textAlign: "center"
                            }}
                            fw={600}
                            fz={{ base: 36, lg: 64 }}>
                            Get in Touch with OZLINK
                        </Title>
                        <Text
                            mt={12}
                            style={{ textAlign: "center" }}
                            fw={500}
                            fz={16}>
                            Have a question or want to learn more about our services? Fill out the form below, <br />
                            and one of our friendly representatives will get back to you as soon as possible. <br />
                            We're here to assist you with all your cleaning needs!
                        </Text>
                    </div>
                </div>
                <div className='tw-mt-16'>
                    <Image
                        h={{ xl: "615px", lg: "600px", md: "585px", sm: "500px", base: "300px" }}
                        style={{
                            zIndex: 9,
                            position: "relative",
                            borderRadius: "40px"
                        }}
                        src="assets/images/contact-us-banner.webp"
                    />
                    <div className='tw-grid lg:tw-grid-cols-2 tw-grid-cols-1 tw-relative'>
                        <img
                            className='tw-absolute tw-top-[-300px] tw-right-0'
                            src='assets/images/brush2.svg'
                            alt='Brush paint' />
                        {formType === "enquiry" ?
                            <div className='tw-p-3 tw-z-[9]'>
                                <div className='tw-rounded-[30px] tw-border-2 lg:tw-w-[85%] tw-w-[100%] tw-border-black tw-bg-white tw-p-6 lg:tw-mt-[-150px] tw-mt-[-70px] lg:tw-ml-12'>
                                    <h5 className='lg:tw-text-[24px] tw-text-[18px] tw-font-[600]'>Enquire about our services</h5>
                                    <Text mt={{ base: 12 }}>Want your commercial space cleaned? Contact our local office and find out more about the commercial space cleaning services we provide in your local area.</Text>
                                    <form className='tw-mt-8'>
                                        <Stack gap={16}>
                                            <Input fz={16} placeholder="Full Name" size="md" radius={10} />
                                            <Input fz={16} placeholder="Contact Number" size="md" radius={10} />
                                            <Input fz={16} placeholder="Email" size="md" radius={10} />
                                            <select
                                                className='tw-border-[2px] tw-border-[#000] tw-rounded-[10px] tw-h-[42px] tw-p-2 tw-text-black'
                                                name="ServiceType"
                                                id="serviceType">
                                                <option className='tw-p-3 tw-mb-3' value="Industrial Cleaning">Industrial Cleaning</option>
                                                <option className='tw-p-3 tw-mb-3' value="Office Cleaning">Office Cleaning</option>
                                                <option className='tw-p-3 tw-mb-3' value="Medical Centre Cleaning">Medical Centre Cleaning</option>
                                                <option className='tw-p-3 tw-mb-3' value="School Cleaning">School Cleaning</option>
                                                <option className='tw-p-3 tw-mb-3' value="Maintenance services">Maintenance services</option>
                                                <option className='tw-p-3 tw-mb-3' value="Security services">Security services</option>
                                            </select>
                                            <Textarea
                                                size='md'
                                                radius={10}
                                                rows={8}
                                                placeholder="Message (Optional)"
                                            />
                                            <Button
                                                mt={18}
                                                h={54}
                                                bg={"black"}
                                                fs={16}
                                                radius={10}
                                                onClick={handleFormSubmit}
                                                fullWidth={false}
                                                w={160} >
                                                Send Enquiry
                                            </Button>
                                        </Stack>
                                    </form>
                                </div>
                            </div> :
                            <div className='tw-p-3 tw-z-[9]'>
                                <div className='tw-rounded-[30px] tw-border-2 lg:tw-w-[85%] tw-w-[100%] tw-border-[#ED3535] tw-bg-white tw-p-6 lg:tw-mt-[-150px] lg:tw-ml-12'>
                                    <h5 className='tw-text-[24px] tw-font-[600] tw-text-[#D82323]'>Send complaint</h5>
                                    <Text>We're sorry to hear you are having problems. Let us know as much information as possible when submitting a complaint.</Text>
                                    <form className='tw-mt-8'>
                                        <Stack gap={18}>
                                            <Input radius={10} placeholder="Full Name" size="lg" />
                                            <Input radius={10} placeholder="Contact Number" size="lg" />
                                            <Input radius={10} placeholder="Email" size="lg" />
                                            <Textarea
                                                radius={10}
                                                size='lg'
                                                rows={8}
                                                placeholder="Message (Optional)"
                                            />
                                            <Button
                                                mt={18}
                                                h={54}
                                                bg={"#ED3535"}
                                                fs={16}
                                                radius={10}
                                                fullWidth={false}
                                                w={160} >
                                                Send Complaint
                                            </Button>
                                        </Stack>
                                    </form>
                                </div>
                            </div>
                        }
                        <div className='lg:tw-block tw-hidden'>
                            {formType === "enquiry" ?
                                <div className='tw-mt-14'>
                                    <Title fz={24} fw={600}>I have a complaint</Title>
                                    <Text fz={16} lh={"24px"} my={16}>We're sorry to hear you are having problems. Let us know as much information as possible when submitting a complaint.</Text>
                                    <Button
                                        bg={"#ED3535"}
                                        onClick={(e) => setFormType(formType === "enquiry" ? "complaint" : "enquiry")}
                                        radius={10}>
                                        Send Complaint
                                    </Button>
                                </div> :
                                <div className='tw-mt-14'>
                                    <Title fz={24} fw={600}>Enquire about our services</Title>
                                    <Text fz={16} lh={"24px"} my={16}>Want your commercial space cleaned? Contact our local office and find out more about the commercial space cleaning services we provide in your local area.</Text>
                                    <Button
                                        bg={"#111"}
                                        onClick={(e) => setFormType(formType === "enquiry" ? "complaint" : "enquiry")}
                                        radius={10}>
                                        Send Enquiry
                                    </Button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

